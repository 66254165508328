<template>
  <div class="remove-user-selectors">
    <Select
      :value="model.employee"
      entity="request"
      alias="requests"
      field="employee"
      field-id="employee_id"
      get-options-action="employees/getEmployeesForSelector"
      autocomplete
      icon="UserIcon"
      :return-object="true"
      :exclude-ids="excludeIds"
      @updateFields="onSelect"
    />
    <Select
      :value="model.manager"
      entity="request"
      alias="requests"
      field="manager"
      field-id="manager_id"
      get-options-action="getManagersAsync"
      autocomplete
      icon="UserIcon"
      :return-object="true"
      :exclude-ids="excludeIds"
      @updateFields="onSelect"
    />
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api';
import Select from '@/components/fields/select/index.vue';

export default {
  components: { Select },
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const model = ref({});
    const onSelect = (e) => {
      model.value = { ...model.value, ...e.model };

      // eslint-disable-next-line camelcase
      const { manager_id, employee_id } = model.value;
      // eslint-disable-next-line camelcase
      if (manager_id && employee_id) {
        emit('allow', { manager_id, employee_id });
      }
    };

    const { id } = toRefs(props);
    const excludeIds = [];
    if (id.value) excludeIds.push(id.value);

    return {
      model,
      onSelect,
      excludeIds,
    };
  },
};
</script>

<style lang="sass" scoped>
  .remove-user-selectors
    margin: 16px 0
</style>
