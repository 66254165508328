<template>
  <b-sidebar
    v-model="isExpanded"
    bg-variant="white"
    no-header
    right
    backdrop
    shadow
    :width="currentBreakPoint === 'xl' ? '70%' : '100%'"
    class="employee-sidebar"
    @hidden="closeSidebar"
  >
    <BOverlay :show="buzy">
      <div class="d-flex flex-column h-100">
        <div class="sidebar-content">
          <div class="sidebar-header d-flex justify-content-between p-1 bg-">
            <div class="title">
              {{ user
                ? t('settings.employee.sidebar.heading-settings')
                : t('settings.employee.sidebar.heading-create') }}
            </div>
            <div class="action">
              <feather-icon
                icon="XIcon"
                size="18"
                @click="closeSidebar"
              />
            </div>
          </div>
          <EmployeeForm
            ref="employeeForm"
            :key="employeeFormKey"
            v-model="userModel"
            :user="user"
            @submit="saveUserData"
            @removeUser="showRemoveUserModal"
          />
          <!-- <RoleForm
            ref="roleForm"
            v-model="userModel.role"
            :user="user"
            @rolesUpdated="onRolesUpdated"
          /> -->
          <!-- <RoleSelector v-model="userModel.role" /> -->
        </div>
        <footer class="sidebar-footer py-1">
          <div class="row m-0">
            <div class="col-md-2" />
            <div class="col-md-10">
              <div class="sidebar-footer-left-col">
                <b-button
                  v-if="$store.getters['user/permission']('users:update')"
                  class="mr-1"
                  variant="primary"
                  @click="saveUserData"
                >
                  {{ t('settings.save') }}
                  <b-spinner
                    v-if="isProcessing"
                    small
                  />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="closeSidebar"
                >
                  {{ t('settings.cancel') }}
                </b-button>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </BOverlay>
    <b-modal
      id="removeUserModal"
      ref="removeUserModal"
      v-model="isRemoveUserModalVisible"
      :title="t('settings.employee.delete-modal.title')"
      :ok-title="t('settings.delete')"
      :cancel-title="t('settings.cancel')"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      :centered="true"
      :ok-disabled="!removeUserAllowed"
      @ok="removeUser"
    >
      <h6>{{ t('settings.employee.delete-modal.confirm-message') }}</h6>
      <RemoveUser
        v-if="user"
        :id="user.id"
        @allow="
          removeUserAllowed = true;
          removeUserManagerId = $event.manager_id;
          removeUserEmployeeId = $event.employee_id;
        "
      />
    </b-modal>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BSidebar,
  BModal,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue';

import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';
import EmployeeForm from '@/views/settings/sections/employee/employeeSidebar/EmployeeForm.vue';
// import RoleForm from '@/views/settings/sections/employee/employeeSidebar/RoleForm.vue';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import { useToast } from '@/hooks/useToast';
import RemoveUser from './RemoveUser.vue';

export default {
  name: 'EmployeeSidebar',
  components: {
    BButton,
    BSidebar,
    BModal,
    BSpinner,
    EmployeeForm,
    BOverlay,
    RemoveUser,
  },
  directives: {
    Ripple,
  },
  props: {
    value: Boolean,
    user: Object,
    buzy: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { dangerToast } = useToast();

    const { value, user } = toRefs(props);
    const currentUser = computed(() => store.getters['user/data']);

    const employeeForm = ref(null);

    const isExpanded = ref(value.value);
    watch(value, (val) => {
      isExpanded.value = val;
    });
    const employeeFormKey = ref(null);
    watch(isExpanded, (val) => {
      emit('input', val);
      if (val) {
        employeeFormKey.value = user.value ? user.value.id : Date.now();
        return;
      }
      employeeForm.value.resetValidationObserver();
    });

    const currentBreakPoint = computed(() => store.getters['app/currentBreakPoint']);
    const userModel = ref({
      firstname: '',
      surname: '',
      phone: '',
      email: '',
      is_active: 1,
      is_serviceman: 1,
      role: 'admin',
    });
    const initialModel = ref(userModel.value);

    const closeSidebar = () => {
      isExpanded.value = false;
      emit('close');
      employeeForm.value.hidePasswordInput();
      userModel.value = {
        firstname: '',
        surname: '',
        phone: '',
        email: '',
        is_active: 1,
        is_serviceman: 1,
        role: 'admin',
      };
    };

    const setUserModel = (userData) => {
      userModel.value = {
        role: userData?.role?.name || 'admin',
        ...['firstname', 'surname', 'phone', 'email', 'is_active', 'is_serviceman']
          .reduce((acc, val) => (
            userData?.[val] ? {
              ...acc,
              [val]: userData[val],
            } : acc
          ), {}),
        ...(userData?.id !== currentUser.value.id ? {
          password: '',
        } : {}),
      };
      initialModel.value = userModel.value;
    };
    watch(user, (val) => {
      setUserModel(val);
    }, { deep: true });

    const removeUserAllowed = ref(false);
    const isRemoveUserModalVisible = ref(false);
    const removeUserManagerId = ref(null);
    const removeUserEmployeeId = ref(null);
    // eslint-disable-next-line max-len
    const showRemoveUserModal = () => {
      isRemoveUserModalVisible.value = true;
      removeUserAllowed.value = false;
      removeUserManagerId.value = null;
      removeUserEmployeeId.value = null;
    };
    const hideRemoveUserModal = () => { isRemoveUserModalVisible.value = false; };
    const removeUser = async () => {
      const { result, data } = await store.dispatch('employees/deleteEmployee', {
        id: user.value.id,
        manager_id: removeUserManagerId.value,
        employee_id: removeUserEmployeeId.value,
      });
      if (result) {
        emit('userListUpdated');
        closeSidebar();
      } else {
        dangerToast(
          t('errors.something-went-wrong.title'),
          t('errors.something-went-wrong.text'),
          data,
        );
      }
      hideRemoveUserModal();
    };

    const isProcessing = ref(false);
    const saveUserData = async () => {
      if (isProcessing.value) return;

      try {
        await employeeForm.value.validate();
      } catch (err) {
        isProcessing.value = false;
        return;
      }

      isProcessing.value = true;
      const { result, data } = await (() => {
        // if edit current user
        if (user.value?.id === currentUser.value.id) {
          return store.dispatch('user/updateProfile', userModel.value);
        }

        // if edit user
        if (user.value && (user.value?.id !== currentUser.value.id)) {
          // Remove pass field if it was not changed
          const model = { ...userModel.value };
          if (userModel.value.password === '') {
            delete model.password;
          }

          return store.dispatch('employees/updateEmployee', {
            employeeId: user.value.id,
            model,
          });
        }

        // if creating user
        return store.dispatch('employees/createEmployee', userModel.value);
      })();
      isProcessing.value = false;
      isExpanded.value = false;
      emit('userListUpdated');

      if (!result) {
        dangerToast(
          t(data?.message ? 'errors.error-title' : 'errors.something-went-wrong.title'),
          t(data?.message || 'errors.something-went-wrong.text'),
        );
      }
    };

    const roleForm = ref(null);

    const isDeletingRole = ref(false);
    const removeRole = async () => {
      await roleForm.value.showDeleteRoleModal();
    };

    const onRolesUpdated = () => {
      emit('userListUpdated');
    };

    return {
      t,

      isExpanded,
      closeSidebar,

      employeeForm,

      currentBreakPoint,

      userModel,

      isRemoveUserModalVisible,
      showRemoveUserModal,
      hideRemoveUserModal,

      removeUser,

      isProcessing,
      saveUserData,

      roleForm,
      isDeletingRole,
      removeRole,

      onRolesUpdated,
      employeeFormKey,
      removeUserAllowed,
      removeUserManagerId,
      removeUserEmployeeId,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/utils";

.employee-sidebar {
  .b-overlay-wrap {
    height: 100%;
  }
  .sidebar-header {
    background-color: $body-bg;
  }
  .sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $white;
    z-index: 3;
  }
}

@include dark-theme {
  .employee-sidebar {
    .sidebar-header {
      background-color: $theme-dark-body-bg;
    }
    .sidebar-footer {
      background-color: $theme-dark-table-bg;
    }
  }
}

.sidebar-content {
  flex-grow: 1;
  position: relative;
  padding-bottom: 70px;
}
</style>
