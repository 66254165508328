<template>
  <div
    class="mb-3 role-selector"
  >
    <EditInput
      ref="input"
      :model="model"
      :toggle-only-by-icon="false"
      type="dropdown"
      class="m-0"
      :options="options"
      @input="onInput"
    >
      {{ model && model.description }}
    </EditInput>
    <div class="text-muted">
      {{ model && model.detailed_description }}
    </div>
  </div>
</template>

<script>
import { useStore } from '@/hooks/useStore';
import {
  computed, toRefs,
} from '@vue/composition-api';
import EditInput from '../editInput/EditInput.vue';

export default {
  components: {
    EditInput,
  },
  props: {
    value: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const roles = computed(() => store.getters['employees/roles']);
    const { value } = toRefs(props);

    const options = computed(() => roles.value.map((i) => (
      {
        ...i,
        label: i.description,
        value: i.name,
      })));

    const model = computed(() => {
      if (!value.value) {
        // eslint-disable-next-line prefer-destructuring
        return options.value[0];
      }
      const findedOption = options.value.find((i) => i.name === value.value);
      if (findedOption) { return findedOption; }
      // eslint-disable-next-line prefer-destructuring
      return options.value[0];
    });

    const onInput = (name) => {
      // model.value = options.value.find((i) => i.name === name);
      emit('input', name);
    };

    return {
      options,
      model,
      onInput,
    };
  },
};
</script>

<style lang="sass">
.role-selector
  border: 1px solid #d8d6de
  padding: 8px 16px
  border-radius: 4px
  position: relative
  padding-top: 28px
  & .dropdown
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    & > button
      padding: 0 16px
      height: 100%
      display: flex
      align-items: flex-start
      padding-top: 8px
    & .edit-input__edit-icon
      right: 16px
    & .dropdown-menu
      width: 100%
</style>
