<template>
  <div class="settings__employees">
    <b-card
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                :placeholder="t('dictionary.search')"
              />
            </b-input-group>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="$store.getters['user/permission']('users:create')"
                variant="primary"
                :disabled="isProcessing"
                @click="toggleSidebar"
              >
                <span class="text-nowrap">
                  {{ t('settings.employee.add-employee') }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <BOverlay
        :show="isProcessing"
        opacity="0.8"
        variant="transparent"
      >
        <b-table
          ref="usersTable"
          class="position-relative min-height"
          hover
          selectable
          select-mode="single"
          :items="employees"
          responsive
          :fields="tableColumns"
          :per-page="0"
          :no-local-sorting="true"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          :empty-text="t('settings.employee.table.empty-text')"
          @row-selected="editEmployee"
        >
          <!-- Column: User -->
          <template #cell(firstname)="{ item: user }">
            <div :class="'d-flex align-items-center ' + (isMobile ? 'gap-5' : '')">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="user.avatar"
                    :variant="
                      `light-${(user.role && rolesMap[user.role.name]
                        && rolesMap[user.role.name].variant) || 'success'}`
                    "
                    :text="avatarText(`${user.firstname} ${user.surname}`)"
                  />
                </template>
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ user.firstname }}
                </b-link>
                <small class="text-muted">@{{ user.firstname }}</small>
              </b-media>
              <span>{{ user.firstname }} {{ user.surname }}</span>
            </div>
          </template>

          <!-- Column: Role -->
          <template #cell(role)="{ item: user }">
            <div>
              <span class="align-text-top text-capitalize">
                <!--Used && instead of ?. coz vue 2 don't support optional chaining in templates-->
                {{ (
                  user.role &&
                  rolesMap[user.role.name] &&
                  rolesMap[user.role.name].description
                ) || '' }}
              </span>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(is_active)="{ item: user }">
            <span
              :class="{
                'text-success': !!user.is_active
              }"
            >
              {{ !!user.is_active
                ? t('settings.employee.table.active')
                : t('settings.employee.table.not-active') }}
            </span>
          </template>
        </b-table>
      </BOverlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center
              justify-content-sm-start
              text-muted
            "
          >
            <span class="mr-1">{{ t('app.table.show-by') }}</span>
            <b-form-group style="width: 90px; margin: 0">
              <app-select
                v-model="pagination.perPage"
                style="width: 100%"
                :searchable="false"
                label="title"
                :clearable="false"
                :options="perPageOptions"
              />
            </b-form-group>
            <span class="ml-1">
              {{ t('app.table.of', { total: totalEmployees }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalPages > 1"
              v-model="pagination.page"
              :total-rows="totalEmployees"
              :per-page="pagination.perPage"
              first-number
              last-number
              :limit="isMobile ? 1 : 5"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <EmployeeSidebar
      v-model="isSidebarExpanded"
      :buzy="isSidebarBuzy"
      :user="currentlyEditingUser"
      @close="onSidebarClose"
      @userListUpdated="getEmployeesList"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton,
  BTable, BMedia, BAvatar, BLink,
  BPagination,
  BInputGroupPrepend, BInputGroup, BFormGroup, BOverlay,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import { computed, ref, watch } from '@vue/composition-api';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import EmployeeSidebar from '@/views/settings/sections/employee/employeeSidebar/EmployeeSidebar.vue';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import { useToast } from '@/hooks/useToast';
import { useRouter } from '@/@core/utils/utils';
import useCache from "@/hooks/useCache";

export default {
  name: 'Employees',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BOverlay,
    EmployeeSidebar,

    AppSelect,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { dangerToast } = useToast();
    const { route, router } = useRouter();
    const isMobile = ref(false);

    isMobile.value = store.state.app.isMobile;

    const usersTable = ref(null);
    const tableColumns = [
      { key: 'firstname', label: 'ФИО', sortable: true },
      { key: 'role', label: 'Роль', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'is_active', label: 'Активность', sortable: true },
    ];

    const employees = ref([]);
    const totalEmployees = ref(0);

    const pagination = ref({
      page: 1,
      perPage: 25,
    });
    const totalPages = computed(() => Math.ceil(totalEmployees.value / pagination.value.perPage));
    const perPageOptions = [10, 25, 50, 100];

    const cache = useCache();
    const cacheParams = ref({
      prefix: 'settingsUsers',
      storage: true,
      url: false,
    });
    const searchQuery = ref('');
    const sortBy = cache.create({
      defaultValue: 'id',
      name: 'sortBy',
      ...cacheParams.value
    });
    const isSortDirDesc = cache.create({
      defaultValue: false,
      name: 'isSortDirDesc',
      ...cacheParams.value
    });

    const isProcessing = ref(false);
    const getEmployees = (payload) => store.dispatch('employees/getUsers', payload);

    const getList = async () => {
      isProcessing.value = true;
      const { result, data } = await getEmployees({
        q: searchQuery.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        ...pagination.value,
      });
      isProcessing.value = false;

      if (result) {
        employees.value = data.list;
        pagination.value.page = data.pagination.page;
        pagination.value.perPage = data.pagination.perPage;
        totalEmployees.value = data.pagination.total;
        return;
      }
      dangerToast(
        t('errors.something-went-wrong.title'),
        t('errors.something-went-wrong.text'),
        data,
      );
    };

    watch([pagination, sortBy, isSortDirDesc], getList, { deep: true });

    watch(searchQuery, () => {
      getList();
    });

    const getEmployeesList = async () => {
      employees.value = [];
      setTimeout(() => {
        getList();
      }, 1000);
    };

    const roleVariantsMap = {
      admin: {
        description: 'Администратор',
        variant: 'success',
        icon: 'ServerIcon',
      },
      'manager-head': {
        description: 'Старший менеджер',
        variant: 'info',
        icon: 'SettingsIcon',
      },
      manager: {
        description: 'Менеджер',
        variant: 'info',
        icons: 'Edit2Icon',
      },
      accountant: {
        description: 'Бухгалтер',
        variant: 'primary',
        icon: 'Edit3Icon',
      },
      'service-head': {
        description: 'Руководитель сервиса',
        variant: 'success',
        icon: 'ServerIcon',
      },
      'service-engineer': {
        description: 'Инженер сервиса',
        variant: 'warning',
        icon: 'ServerIcon',
      },
    };

    const getRoles = () => store.dispatch('employees/getRoles');
    const getRolesEntities = () => store.dispatch('employees/getRolesEntities');
    const roles = computed(() => store.getters['employees/roles']);

    const rolesMap = computed(() => roles.value.reduce((acc, val) => ({
      ...acc,
      [val.name]: {
        ...val,
        ...(roleVariantsMap?.[val.name]
          ? roleVariantsMap[val.name]
          : {}),
      },
    }), {}));

    (async () => {
      isProcessing.value = true;
      try {
        await store.dispatch('user/getData');
        await getRoles();
        await getRolesEntities();
        await getList();
      } catch (err) {
        dangerToast(
          t('errors.something-went-wrong.title'),
          t('errors.something-went-wrong.text'),
          err,
        );
      } finally {
        isProcessing.value = false;
      }
    })();

    const isSidebarExpanded = ref(false);
    const toggleSidebar = () => {
      isSidebarExpanded.value = !isSidebarExpanded.value;
    };

    const currentlyEditingUser = ref(null);
    const redirecting = ref(false);
    const editEmployee = (employee) => {
      if (employee.length) {
        // eslint-disable-next-line prefer-destructuring
        currentlyEditingUser.value = employee[0];
        if (!redirecting.value) {
          router.push(`/settings/users/${employee[0].id}`).catch();
          redirecting.value = true;
        }
        usersTable.value.clearSelected();
      }
    };

    const isSidebarBuzy = ref(false);
    const onUpdateEmployeeId = async (_id) => {
      const id = _id;
      if (!currentlyEditingUser.value) {
        if (id) {
          isSidebarExpanded.value = true;
          isSidebarBuzy.value = true;
          const { result, data } = await store.dispatch('employees/getEmployee', id);
          isSidebarBuzy.value = false;
          if (result) {
            currentlyEditingUser.value = data;
          } else { redirecting.value = false; return; }
        } else { redirecting.value = false; return; }
      } else {
        isSidebarExpanded.value = true;
      }
      redirecting.value = false;
    };
    const routeId = computed(() => route.value.params.id || route.value.query.id);
    watch(routeId, (id) => onUpdateEmployeeId(id), { immediate: true });

    const onSidebarClose = () => {
      router.push({ path: '/settings/users' });
      currentlyEditingUser.value = null;
    };

    return {
      t,

      usersTable,
      tableColumns,
      isMobile,

      rolesMap,

      isSidebarExpanded,
      toggleSidebar,

      employees,
      totalEmployees,

      pagination,
      totalPages,
      perPageOptions,

      searchQuery,
      sortBy,
      isSortDirDesc,

      isProcessing,
      getEmployeesList,

      currentlyEditingUser,
      editEmployee,
      onSidebarClose,

      avatarText,
      isSidebarBuzy,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.per-page-selector {
    width: 90px;
}

.min-height {
    min-height: 150px;
}

.settings__employees {
  .media-body {
    display: none;
  }
}
</style>
