var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-user pt-4 mb-2"},[(_vm.user && _vm.user.id !== _vm.currentUser.id && _vm.$store.getters['user/permission']('users:delete'))?_c('BDropdown',{staticClass:"user-actions-dropdown",attrs:{"menu-class":"dropdown-menu-media","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"MoreVerticalIcon","size":"21"}})]},proxy:true}],null,false,1284166958)},[_c('BDropdownItem',{staticClass:"remove-user-dropdown-item",on:{"click":_vm.onDeleteUser}},[_c('FeatherIcon',{staticClass:"mr-1 text-danger",attrs:{"icon":"Trash2Icon"}}),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.t('settings.delete'))+" ")])],1)],1):_vm._e(),_c('ValidationObserver',{ref:"validationObserver"},[_c('b-row',{staticClass:"m-0"},[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"md":"2"}},[_c('b-avatar',{staticClass:"mb-2",attrs:{"size":"100px","variant":"light-danger","src":_vm.user && _vm.user.avatar,"square":true,"text":_vm.avatarText(_vm.user && _vm.user.fullName)}})],1),_c('b-col',{attrs:{"md":"10"}},[_c('b-form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.t('auth.field.firstname'),"label-for":"user-edit-firstname"}},[_c('ValidationProvider',{attrs:{"name":_vm.t('auth.field.firstname'),"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.t('auth.field.firstname'),"state":errors.length > 0 ? false : null,"disabled":!_vm.$store.getters['user/permission']('users:update')},model:{value:(_vm.localModel.firstname),callback:function ($$v) {_vm.$set(_vm.localModel, "firstname", $$v)},expression:"localModel.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.t('auth.field.surname'),"label-for":"user-edit-surname"}},[_c('ValidationProvider',{attrs:{"name":_vm.t('auth.field.surname'),"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.t('auth.field.surname'),"state":errors.length > 0 ? false : null,"disabled":!_vm.$store.getters['user/permission']('users:update')},model:{value:(_vm.localModel.surname),callback:function ($$v) {_vm.$set(_vm.localModel, "surname", $$v)},expression:"localModel.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.t('auth.field.email'),"label-for":"user-edit-email"}},[_c('ValidationProvider',{attrs:{"name":_vm.t('auth.field.email'),"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user-edit-email","placeholder":_vm.t('auth.field.email'),"state":errors.length > 0 ? false : null,"type":"email","disabled":!_vm.$store.getters['user/permission']('users:update')},model:{value:(_vm.localModel.email),callback:function ($$v) {_vm.$set(_vm.localModel, "email", $$v)},expression:"localModel.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('PhoneInput',{attrs:{"label":_vm.t('auth.field.phone'),"disabled":!_vm.$store.getters['user/permission']('users:update')},model:{value:(_vm.localModel.phone),callback:function ($$v) {_vm.$set(_vm.localModel, "phone", $$v)},expression:"localModel.phone"}})],1)],1),_c('div',[_c('div',{staticClass:"col-form-label",domProps:{"innerHTML":_vm._s(_vm.t('settings.employee.roles.title'))}}),_c('RoleSelector',{model:{value:(_vm.localModel.role),callback:function ($$v) {_vm.$set(_vm.localModel, "role", $$v)},expression:"localModel.role"}})],1),_c('div',{staticClass:"mt-1 row"},[_c('div',{staticClass:"col-6"},[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"name":"check-button","switch":"","checked":!!_vm.localModel.is_active,"disabled":!_vm.$store.getters['user/permission']('users:update')},on:{"change":function($event){_vm.localModel.is_active = $event ? 1 : 0}}},[_vm._v(" "+_vm._s(_vm.t('settings.employee.activity'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":!!_vm.localModel.is_serviceman,"name":"check-button","switch":"","disabled":!_vm.$store.getters['user/permission']('users:update')},on:{"change":function($event){_vm.localModel.is_serviceman = $event ? 1 : 0}}},[_vm._v(" "+_vm._s(_vm.t('settings.employee.works-contractor'))+" ")])],1),(_vm.user && _vm.user.id !== _vm.currentUser.id &&
                  _vm.$store.getters['user/permission']('users:update'))?_c('div',{staticClass:"col-6"},[(!_vm.isPasswordFieldVisible)?_c('BLink',{staticClass:"text-primary cursor-pointer",on:{"click":_vm.showPasswordInput}},[_c('u',[_vm._v(" "+_vm._s(_vm.t('settings.employee.change-password'))+" ")])]):_c('div',[_c('b-form-group',{staticClass:"m-0"},[_c('label',[_vm._v(" "+_vm._s(_vm.user ? _vm.t('settings.employee.password.new') : _vm.t('settings.employee.password.old'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.t('auth.field.password'),"rules":"min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('InputPassword',{attrs:{"errors":errors},model:{value:(_vm.localModel.password),callback:function ($$v) {_vm.$set(_vm.localModel, "password", $$v)},expression:"localModel.password"}})]}}],null,false,752422284)})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"change-password-cancel mt-1",attrs:{"variant":"flat-danger"},on:{"click":_vm.hidePasswordInput}},[_vm._v(" "+_vm._s(_vm.t('settings.cancel'))+" ")])],1)],1)],1):_vm._e()])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }