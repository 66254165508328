<template>
  <div
    class="sidebar-user pt-4 mb-2"
  >
    <BDropdown
      v-if="user && user.id !== currentUser.id && $store.getters['user/permission']('users:delete')"
      class="user-actions-dropdown"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          class="text-body"
          icon="MoreVerticalIcon"
          size="21"
        />
      </template>
      <BDropdownItem
        class="remove-user-dropdown-item"
        @click="onDeleteUser"
      >
        <FeatherIcon
          icon="Trash2Icon"
          class="mr-1 text-danger"
        />
        <span class="text-danger">
          {{ t('settings.delete') }}
        </span>
      </BDropdownItem>
    </BDropdown>
    <ValidationObserver ref="validationObserver">
      <b-row class="m-0">
        <b-col
          md="2"
          class="d-flex justify-content-center"
        >
          <b-avatar
            class="mb-2"
            size="100px"
            variant="light-danger"
            :src="user && user.avatar"
            :square="true"
            :text="avatarText(user && user.fullName)"
          />
        </b-col>
        <b-col md="10">
          <b-form @keyup.enter="onSubmit">
            <b-row>
              <b-col>
                <b-form-group
                  :label="t('auth.field.firstname')"
                  label-for="user-edit-firstname"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    :name="t('auth.field.firstname')"
                    rules="required|min:3"
                  >
                    <b-form-input
                      v-model="localModel.firstname"
                      :placeholder="t('auth.field.firstname')"
                      :state="errors.length > 0 ? false : null"
                      :disabled="!$store.getters['user/permission']('users:update')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="t('auth.field.surname')"
                  label-for="user-edit-surname"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    :name="t('auth.field.surname')"
                    rules="required|min:3"
                  >
                    <b-form-input
                      v-model="localModel.surname"
                      :placeholder="t('auth.field.surname')"
                      :state="errors.length > 0 ? false : null"
                      :disabled="!$store.getters['user/permission']('users:update')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <b-form-group
                  :label="t('auth.field.email')"
                  label-for="user-edit-email"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    :name="t('auth.field.email')"
                    rules="email|required"
                  >
                    <b-form-input
                      id="user-edit-email"
                      v-model="localModel.email"
                      :placeholder="t('auth.field.email')"
                      :state="errors.length > 0 ? false : null"
                      type="email"
                      :disabled="!$store.getters['user/permission']('users:update')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col>
                <PhoneInput
                  v-model="localModel.phone"
                  :label="t('auth.field.phone')"
                  :disabled="!$store.getters['user/permission']('users:update')"
                />
              </b-col>
            </b-row>
            <div>
              <div
class="col-form-label"
v-html="t('settings.employee.roles.title')"
/>
              <RoleSelector v-model="localModel.role" />
            </div>
            <div class="mt-1 row">
              <div class="col-6">
                <b-form-checkbox
                  name="check-button"
                  switch
                  class="mb-1"
                  :checked="!!localModel.is_active"
                  :disabled="!$store.getters['user/permission']('users:update')"
                  @change="localModel.is_active = $event ? 1 : 0"
                >
                  {{ t('settings.employee.activity') }}
                </b-form-checkbox>

                <b-form-checkbox
                  :checked="!!localModel.is_serviceman"
                  name="check-button"
                  switch
                  :disabled="!$store.getters['user/permission']('users:update')"
                  @change="localModel.is_serviceman = $event ? 1 : 0"
                >
                  {{ t('settings.employee.works-contractor') }}
                </b-form-checkbox>
              </div>
              <div
                v-if="user && user.id !== currentUser.id &&
                  $store.getters['user/permission']('users:update')"
                class="col-6"
              >
                <BLink
                  v-if="!isPasswordFieldVisible"
                  class="text-primary cursor-pointer"
                  @click="showPasswordInput"
                >
                  <u>
                    {{ t('settings.employee.change-password') }}
                  </u>
                </BLink>
                <div v-else>
                  <b-form-group class="m-0">
                    <label>
                      {{ user
                        ? t('settings.employee.password.new')
                        : t('settings.employee.password.old') }}
                    </label>
                    <ValidationProvider
                      #default="{ errors }"
                      :name="t('auth.field.password')"
                      rules="min:6"
                    >
                      <InputPassword
                        v-model="localModel.password"
                        :errors="errors"
                      />
                    </ValidationProvider>
                  </b-form-group>
                  <div class="d-flex justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      class="change-password-cancel mt-1"
                      @click="hidePasswordInput"
                    >
                      {{ t('settings.cancel') }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BFormInput,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  // BNavItemDropdown,
  BButton,
  BFormGroup,
  BLink,
  // BInputGroupAppend,
  // BInputGroup,
  BForm,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { avatarText } from '@core/utils/filter';
import InputPassword from '@/components/inputPassword/InputPassword.vue';
import PhoneInput from '@/components/phoneInput/PhoneInput.vue';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import RoleSelector from '@/components/fields/RoleSelector.vue';

export default {
  name: 'CreateEmployeeForm',
  components: {
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormGroup,
    BLink,
    BForm,
    PhoneInput,
    ValidationObserver,
    ValidationProvider,
    InputPassword,
    RoleSelector,
  },
  directives: {
    Ripple,
  },
  props: {
    user: {
      type: Object,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { getters } = useStore();

    const validationObserver = ref(null);
    const validate = async () => {
      const success = await validationObserver.value.validate();
      return success
        ? Promise.resolve()
        : Promise.reject();
    };
    const resetValidationObserver = () => {
      validationObserver.value.reset();
    };

    const { value: model, user } = toRefs(props);
    const localModel = ref(model.value);

    watch(model, (val) => {
      localModel.value = val;
    }, { deep: true });

    watch(localModel, () => {
      // emit('input', val);
    }, { deep: true });

    const currentUser = computed(() => getters['user/data']);

    const newPassword = ref('');
    const isPasswordFieldVisible = ref(false);
    const showPasswordInput = () => {
      isPasswordFieldVisible.value = true;
    };
    const hidePasswordInput = () => {
      isPasswordFieldVisible.value = false;
      localModel.value.password = '';
    };

    const onSubmit = () => {
      emit('submit');
    };

    const onDeleteUser = () => {
      emit('removeUser', user.value.id);
    };

    return {
      t,

      validationObserver,
      validate,
      resetValidationObserver,

      localModel,
      currentUser,

      newPassword,
      isPasswordFieldVisible,
      showPasswordInput,
      hidePasswordInput,

      onSubmit,
      onDeleteUser,

      avatarText,
      required,
      email,
    };
  },
};
</script>

<style lang="scss">
.col-form-label {
  padding-bottom: 0;
  margin-bottom: 0.1557rem;
  font-size: 0.857rem;
}
.sidebar-user {
  width: 100%;
  position: relative;
}

.user-actions-dropdown {
  position: absolute;
  right: 15px;
  top: 10px;

  .remove-user-dropdown-item {
    &:hover {
      background: rgba(234, 84, 85, 0.12);
    }

    a {
      &:hover {
        background-color: unset;
      }
    }
  }

  .dropdown-toggle {
    background: transparent !important;
    padding: 0;
    border: none !important;

    &::after {
      display: none;
    }

    &:hover {
      box-shadow: none !important;
    }
  }
}
</style>
